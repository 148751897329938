(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.GoogleMapSkin'] = {
  "react": [
    [
      "div",
      "mapContainer",
      [],
      {}
    ]
  ],
  "css": {
    "%[data-state~=\"squared\"],%[data-state~=\"squared\"] %mapContainer": "border-radius:0;",
    "%mapContainer": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.map.GoogleMapDefault'] = {
  "react": [
    [
      "div",
      "mapContainer",
      [],
      {}
    ]
  ],
  "params": {
    "brd": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "SIZE"
  },
  "paramsDefaults": {
    "brd": "color_15",
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "0"
  },
  "css": {
    "%[data-state~=\"squared\"],%[data-state~=\"squared\"] %mapContainer": "border-radius:0;",
    "%": "background:[brd];[rd]  [shd]",
    "%mapContainer": "display:inline-block;overflow:hidden;[rd]  position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];-webkit-mask-image:-webkit-radial-gradient(circle, #fff, #000);mask-image:radial-gradient(circle, #fff, #000);"
  }
}
 skins['wysiwyg.viewer.skins.map.GoogleMapLiftedShadow'] = {
  "react": [
    [
      "div",
      null,
      [
        "_left",
        "_shd"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_right",
        "_shd"
      ],
      {}
    ],
    [
      "div",
      "mapContainer",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL",
    "brd": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "brd": "color_15",
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "0"
  },
  "css": {
    "%[data-state~=\"squared\"],%[data-state~=\"squared\"] %mapContainer": "border-radius:0;",
    "%_shd": "position:absolute;bottom:-26px;width:165px;height:26px;background-image:url([tdr]liftedshadow_medium.png);background-repeat:no-repeat;",
    "%_left": "left:-20px;background-position:0 0;",
    "%_right": "right:-20px;background-position:100% 0;",
    "%": "background-color:[brd];[rd]  [shd]",
    "%mapContainer": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];overflow:hidden;"
  }
}
 skins['wysiwyg.viewer.skins.map.GoogleMapSloppy'] = {
  "react": [
    [
      "div",
      null,
      [
        "_brd",
        "_one"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_brd",
        "_two"
      ],
      {}
    ],
    [
      "div",
      "mapContainer",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%[data-state~=\"squared\"],%[data-state~=\"squared\"] %mapContainer": "border-radius:0;",
    "%_brd": "background-image:url([tdr]sloppyframe.png);background-repeat:no-repeat;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_one": "background-position:0 0;bottom:3px;right:3px;",
    "%_two": "background-position:100% 100%;top:3px;left:3px;",
    "%mapContainer": "position:absolute;top:9px;bottom:9px;left:9px;right:9px;"
  }
}

        return skins;
    }));