define([
    'santa-components',
    'lodash',
    'coreUtils',
    'reactDOM',
    'components',
    'skins',
    'googleMap/skins/skins.json',
    'googleMap/getGoogleMapsData',
    'componentsCore',
    'googleMap/bi/events'
], function (
    santaComponents,
    _,
    coreUtils,
    ReactDOM,
    components,
    skinsPackage,
    skinsJson,
    getGoogleMapsData,
    componentsCore,
    biEvents
) {
    'use strict';

    const SUPPORTED_LANGS = ['da', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'nl', 'no', 'pl', 'pt', 'ru', 'sv', 'tr'];
    const DEFAULT_ZOOM = 14;
    const LOCALES = {pt: 'pt-BR'};
    const APP_NAME = 'google-maps';

    const getLanguage = function (props) {
        const languageProperty = props.compProp.language;
        const userLanguage = props.userLanguage;

        const lang = languageProperty === 'userLang' ? userLanguage : languageProperty;
        const language = _.includes(SUPPORTED_LANGS, lang) ? lang : 'en';
        return LOCALES[language] || language;
    };

    function getIframeUrl(props) {
        const mapProps = getGoogleMapsData(props);
        const pickProps = ['defaultLocation', 'showZoom', 'showStreetView', 'showMapType', 'fullscreenControl'];
        const initialProps = _.assign(_.pick(mapProps, pickProps), {id: props.compData.id});
        const initialParams = _.reduce(initialProps, (result, value, key) => result.concat(`&${key}=${value}`), '');
        const clientKeyValueFromExperiment = props.getExperimentValue('googleMapsClientKey');
        const clientKeyParam = clientKeyValueFromExperiment ? `&clientKey=${clientKeyValueFromExperiment}` : ''; //Google Client Key from experiment
        const queryString = `language=${getLanguage(props)}${initialParams}${clientKeyParam}`;

        const santaBase = props.isExperimentOpen('se_addDesignerPage') ? props.santaBase.replace('https://static.parastorage.com/services/', 'https://editor.wix.com/_partials/') : props.santaBase;

        return coreUtils.urlUtils.joinURL(santaBase || '', `static/external/googleMap.html?${queryString}`);
    }

    function getPublicState(state, propsInfo) {
        if (!_.get(propsInfo, 'data.locations')) {
            return {
                zoom: DEFAULT_ZOOM,
                center: {},
                locations: [],
                compData: _.get(propsInfo, 'data'),
                compProp: _.get(propsInfo, 'props'),
                componentPreviewState: _.get(propsInfo, 'componentPreviewState'),
                componentViewMode: _.get(propsInfo, 'componentViewMode'),
                isPreviewMode: _.get(propsInfo, 'isPreviewMode')
            };
        }
        const zoom = _.get(state, ['zoom'], propsInfo.props.zoom || DEFAULT_ZOOM);
        const defaultCenter = propsInfo.data.locations[propsInfo.data.defaultLocation] || {};
        const center = _.get(state, ['center'], {
            longitude: defaultCenter.longitude,
            latitude: defaultCenter.latitude
        });
        return {
            zoom,
            center,
            locations: _.cloneDeep(propsInfo.data.locations),
            compData: propsInfo.data,
            compProp: propsInfo.props,
            componentPreviewState: propsInfo.componentPreviewState,
            componentViewMode: propsInfo.componentViewMode,
            isPreviewMode: propsInfo.isPreviewMode
        };
    }

    /**
     * @class components.GoogleMap
     * @extends {core.skinBasedComp}
     */
    const googleMap = {
        displayName: 'GoogleMap',
        mixins: [componentsCore.mixins.skinBasedComp, santaComponents.mixins.compStateMixin(getPublicState)],
        statics: {
            compSpecificIsDomOnlyOverride: () => false,
            behaviors: {
                getVisibleMarkers: {methodName: 'getVisibleMarkers'},
                setMapZoom: {methodName: 'setMapZoom', params: ['zoom']},
                setMapCenter: {methodName: 'setMapCenter', params: ['longitude', 'latitude']}
            }
        },
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired,
            santaBase: santaComponents.santaTypesDefinitions.santaBase.isRequired,
            cannotHideIframeWithinRoundedCorners: santaComponents.santaTypesDefinitions.mobile.cannotHideIframeWithinRoundedCorners.isRequired,
            userLanguage: santaComponents.santaTypesDefinitions.WixUserSantaTypes.userLanguage.isRequired,
            componentPreviewState: santaComponents.santaTypesDefinitions.RenderFlags.componentPreviewState,
            isPreviewMode: santaComponents.santaTypesDefinitions.isPreviewMode.isRequired,
            isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            getExperimentValue: santaComponents.santaTypesDefinitions.getExperimentValue,
            locations: santaComponents.santaTypesDefinitions.GoogleMap.locations,
            reportBI: santaComponents.santaTypesDefinitions.reportBI.isRequired
        },
        getInitialState() {
            const state = {};
            if (this.props.cannotHideIframeWithinRoundedCorners()) {
                state.$corners = 'squared';
            }

            this.restartMap(this.props);

            return _.assign(getPublicState(null, {
                props: this.props.compProp,
                data: this.props.compData,
                locations: _.cloneDeep(this.props.locations),
                compData: this.props.compData,
                compProp: this.props.compProp,
                componentPreviewState: this.props.componentPreviewState,
                componentViewMode: this.props.componentViewMode,
                isPreviewMode: this.props.isPreviewMode
            }), state);
        },
        componentDidMount() {
            this.iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
            window.addEventListener('message', this.googleMapsMessageHandler);

            this.iFrameNode.onload = function () {
                const newMapProperties = getGoogleMapsData(this.props);
                this.iFrameNode.contentWindow.postMessage({type: 'SET_INITIAL_LOCATIONS', data: JSON.stringify(newMapProperties)}, '*');
            }.bind(this);
        },
        // eslint-disable-next-line complexity
        googleMapsMessageHandler(message) {
            try {
                const messageData = JSON.parse(message.data);
                // TODO: filter all messages by id?
                switch (messageData.type) {
                    case 'MAP_CLICKED':
                        this.onMapClicked(messageData.data);
                        break;
                    case 'MARKER_CLICKED':
                        this.onMarkerClicked(messageData.data);
                        break;
                    case 'ZOOM_UPDATED':
                        this.zoomChanged(messageData.data);
                        break;
                    case 'CENTER_UPDATED':
                        this.centerChanged(messageData.data);
                        break;
                    case 'LOADING_START':
                        this.onLoadingStart(messageData.id);
                        break;
                    case 'LOADING_FINISH':
                        this.onLoadingFinish(messageData.id);
                        break;
                    default:
                        return;
                }
            } catch (e) {
                return;
            }
        },
        componentWillUnmount() {
            window.removeEventListener('message', this.googleMapsMessageHandler);
        },
        setMapZoom(zoom, resolveSdk) {
            this.setState({zoom});
            new Promise(resolve => {
                window.addEventListener('message', message => {
                    try {
                        const data = JSON.parse(message.data);
                        if (data.type === 'SET_ZOOM_FINISHED') {
                            resolve();
                        }
                    } catch (e) {
                        return;
                    }
                });
                const iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
                iFrameNode.contentWindow.postMessage({type: 'SET_ZOOM', data: zoom}, '*');
            }).then(() => {
                resolveSdk();
            });
        },
        setMapCenter(longitude, latitude, resolveSdk) {
            this.setState({center: {
                longitude,
                latitude
            }});
            new Promise(resolve => {
                window.addEventListener('message', message => {
                    try {
                        const data = JSON.parse(message.data);
                        if (data.type === 'SET_CENTER_FINISHED') {
                            resolve(data.data);
                        }
                    } catch (e) {
                        return;
                    }
                });
                const iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
                iFrameNode.contentWindow.postMessage({type: 'SET_CENTER', data: JSON.stringify({longitude, latitude})}, '*');
            }).then(() => {
                resolveSdk();
            });
        },
        centerChanged(center) {
            this.setState({center});
        },
        zoomChanged(zoom) {
            this.setState({zoom});
        },
        getVisibleMarkers(resolveSdk) {
            new Promise(resolve => {
                window.addEventListener('message', message => {
                    try {
                        const data = JSON.parse(message.data);
                        if (data.type === 'MARKERS') {
                            resolve(data.data);
                        }
                    } catch (e) {
                        return;
                    }
                });
                const iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
                iFrameNode.contentWindow.postMessage({type: 'GET_MARKERS'}, '*');
            }).then(value => {
                resolveSdk(value);
            });
        },
        onMapClicked(mapData) {
            this.handleAction(coreUtils.siteConstants.ACTION_TYPES.GOOGLE_MAP_CLICKED, mapData);
        },
        onMarkerClicked(markerData) {
            markerData = _.assign(markerData, _.pick(markerData.location, ['longitude', 'latitude']));
            this.handleAction(coreUtils.siteConstants.ACTION_TYPES.GOOGLE_MAP_MARKER_CLICKED, markerData);
        },
        componentWillReceiveProps(newProps) {
            const newMapProperties = getGoogleMapsData(newProps);
            const lastMapProperties = getGoogleMapsData(_.assign({}, this.state, {compData: this.prevCompData}));
            if (!_.isEqual(lastMapProperties, newMapProperties)) {
                if (getLanguage(this.props) !== getLanguage(newProps)) {
                    this.restartMap(newProps);
                    return;
                }
                const shouldKeepMarkers = this.shouldKeepMarkers(newProps.compData.locations);
                this.updateMapParams(newMapProperties, shouldKeepMarkers);
                this.setState({
                    locations: _.cloneDeep(newProps.locations),
                    compData: newProps.compData,
                    compProp: newProps.compProp,
                    componentPreviewState: newProps.componentPreviewState,
                    componentViewMode: newProps.componentViewMode,
                    isPreviewMode: newProps.isPreviewMode
                });
            }
        },
        shouldKeepMarkers(newLocations) {
            return _.isEqual(this.state.locations, newLocations);
        },
        updateMapParams(msg, shouldKeepMarkers) {
            const iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
            iFrameNode.contentWindow.postMessage(JSON.stringify(_.assign({}, msg, {shouldKeepMarkers: !!shouldKeepMarkers})), '*');
        },
        restartMap(props) {
            this.iframeUrl = getIframeUrl(props);
            this.a11yTitle = coreUtils.translationsLoader.getTranslation('component_label', getLanguage(props), 'COMPONENT_LABEL_googleMapsTitle');
        },
        getSkinProperties() {
            this.prevCompData = _.cloneDeep(this.state.compData);
            return {
                '': {
                    tabIndex: 0,
                    title: this.a11yTitle,
                    'aria-label': this.a11yTitle,
                    'data-src': this.iframeUrl,
                    tagName: 'wix-iframe'
                },
                'mapContainer': {
                    key: 'mapContainer',
                    children: [
                        santaComponents.utils.createReactElement('iframe', {
                            ref: 'iframe',
                            'data-src': this.iframeUrl,
                            width: '100%',
                            height: '100%',
                            frameBorder: '0',
                            allowFullScreen: true,
                            scrolling: 'no',
                            title: this.a11yTitle,
                            'aria-label': this.a11yTitle
                        })
                    ]
                }
            };
        },
        onLoadingStart(id) {
            const {compData, reportBI} = this.props;
            if (id === compData.id) {
                reportBI(biEvents.GOOGLE_MAPS_START_TO_LOAD, {
                    appName: APP_NAME
                });
            }
        },
        onLoadingFinish(id) {
            const {compData, reportBI} = this.props;
            if (id === compData.id) {
                reportBI(biEvents.GOOGLE_MAPS_FINISHED_LOAD, {
                    appName: APP_NAME
                });
            }
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.GoogleMap', googleMap);
    components.translationRequirementsChecker.registerCommonLanguageRequirement(
        'wysiwyg.viewer.components.GoogleMap',
        (siteData, compInfo) => _.get(compInfo, ['properties', 'language']));

    skinsPackage.skinsMap.addBatch(skinsJson);

    return googleMap;
});
